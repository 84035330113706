import React from 'react';
import injectSheet from 'react-jss';
import ProgramGroup from "./ProgramGroup";
import {ProgramType} from "./ProgramEntry";

const styles = theme => {
    let background = theme.palette.primary.dark;
    let color = theme.palette.primary.light;
//    background =  theme.palette.primary.light;
//    color = theme.palette.background;
    color = theme.palette.backgroundSecondary;
    return {
        root: {
//            flex: '1 1 auto',
            display: 'flex',
            [theme.breakpoints.up('xxl')]: {
                flexDirection: 'column',
            },
            [theme.breakpoints.down('xxl')]: {
                //flexDirection: 'column',
                marginTop: '4rem',
            },
        },
        header: {
            [theme.breakpoints.up('xxl')]: {
                position: 'sticky',
                top: '4.125rem',
                background: theme.palette.background,
                zIndex: 150,
                opacity: .8,
            },
//            display: 'flex',
            flex: '0 0 auto',
            flexDirection: 'column',
            alignItems: 'flex-start',
            textTransform: 'uppercase',
            color,
            [theme.breakpoints.down('xxl')]: {
                flex: '0 0 auto',
                width: '10rem',
            },
            [theme.breakpoints.down('sm')]: {
                width: '5.7rem',
            },
            [theme.breakpoints.down('xxs')]: {
                position: 'sticky',
                top: 100,
                alignSelf: 'flex-start',
            },
        },
        day: {
            fontSize: '1em',
//            background,
//            borderRadius: 4,
//            padding: '.25rem 1rem',
//            [theme.breakpoints.down('sm')]: {
//                padding: '.25rem .5rem',
//            },
        },
        date: {
                marginTop: '1rem',
//            background,
//            borderRadius: 4,
//            padding: '1rem 1rem',
//            [theme.breakpoints.down('xxl')]: {
//                fontSize: '1.5rem'
//            },
//            [theme.breakpoints.down('sm')]: {
//                padding: '.75rem .75rem',
//            },
        },
        content: {
        },
        slam: {
            display: 'flex',
            alignItems: 'flex-start',
            flex: '1 1 auto',
            [theme.breakpoints.down('xxl')]: {
                flexWrap: 'wrap',
                flexDirection: 'column',
                alignItems: 'stretch',
            },
            [theme.breakpoints.down('xxs')]: {
                flexDirection: 'column',
            },
            [theme.breakpoints.down('xxs')]: {
                '& > *': {
                    width: '100%',
                    marginRight: 0,
                },
            },
        },
        preSlam: {
            [theme.breakpoints.up('xxl')]: {
                height: '6rem',
                height: '9rem',
            },
        },
        postSlam: {
            [theme.breakpoints.up('xxl')]: {
                height: '6rem',
                height: '9rem',
            },
        },
    };
};

const ProgramDay = ({day, date, events, classes}) => {

    let singleGroup = events.length===1;
    let preSlam = null;
    let postSlam = null;
    const slam = [];
    events.forEach(({time, events}, index) => {
        //TODO: check if only 1 group
        //TODO: check if 2 group and both equal
        let programGroup = (<ProgramGroup key={index} title={(<time>{time}</time>)} events={events.reverse()} singleGroup={singleGroup}/>);
        if(!singleGroup && events[0].type === ProgramType.SPECIAL){
            if(slam.length==0){
                preSlam = programGroup;
            } else {
                postSlam = programGroup;
            }
        } else {
            slam.push(programGroup);
        }
    });


    return (
        <section className={classes.root}>
            <header className={classes.header}>
                <h4 className={classes.day}>Tag {day}</h4>
                <h3 className={classes.date}><time>{date.format('dd D. M.')}</time></h3>
            </header>
            <div className={classes.content}>
                <div className={classes.preSlam}>{preSlam}</div>
                <div className={classes.slam}>{slam}</div>
                <div className={classes.postSlam}>{postSlam}</div>
            </div>
        </section>
    );
};


export default injectSheet(styles)(ProgramDay);