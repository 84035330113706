import React from 'react';
import { graphql } from "gatsby"
import 'dayjs/locale/de';
import dayjs from "dayjs";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilePdf} from '@fortawesome/free-solid-svg-icons/faFilePdf';

import GatsbyImage from 'gatsby-image';
import injectSheet from 'react-jss';

import Layout from '../containers/layout';
import ProgramDay from "../components/program/ProgramDay";
import Program from "../components/program";
import {ProgramType} from "../components/program/ProgramEntry";
import Box from "../components/Box";

import regelwerk from '../assets/Regelwerk-SLAM-2018.pdf';
import programPDF from '../assets/SLAM2018_Programm_Web.pdf';
import BoxContent from "../components/box/BoxContent";
import SmallBox from "../components/SmallBox";
import Link from "../components/Link";

const castProgramType = (slug) => {
  switch (slug){
      case 'rahmenprogram':
          return ProgramType.SPECIAL;
      case 'team':
          return ProgramType.TEAM;
      case 'einzel':
      default:
          return ProgramType.SINGLE;
  }
};

const styles = theme => ({
    content: {
        marginTop: '2rem',
    },
    split: {
        display: 'flex',
        [theme.breakpoints.down('xxs')]: {
            flexDirection: 'column-reverse',
        },
    },
    contentText: {
        flex: '1 1 auto',
    },
    contentImage: {
        transition: 'transform .15s ease-in',
        transform: 'rotateZ(0deg)',
        flex: '0 0 auto',
        width: '25%',
        marginTop: '.8em',
        marginLeft: '1em',
        maxWidth: 400,
        '& > svg':{
            display: 'none',
        },
        '&:hover': {
            color: theme.palette.secondary.light,
        },
        [theme.breakpoints.up('xxs')]: {
            '&:hover':{
                transform: 'rotateZ(10deg)',
            },
        },
        [theme.breakpoints.up('sm')]: {
            margin: 0,
            width: '20%',
            position: 'relative',
            left: 50,
        },
        [theme.breakpoints.up('lg')]: {
            width: '12%',
            '& .large': {
                display: 'none',
            },
        },
        [theme.breakpoints.down('lg')]: {
            '& .small': {
                display: 'none',
            },
            width: '20%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '30%',
        },
        [theme.breakpoints.down('xxs')]: {
            width: '100%',
            marginLeft: 0,
            '& > svg':{
                display: 'inline',
                marginRight: '.5em',
            },
            '& > .gatsby-image-wrapper':{
                display: 'none',
            },
        },
    },

    infoContainer: {
        marginBottom: '2rem',
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            '& > section': {
                width: '50%',
                '&:first-child': {
                    marginRight: '1em',
                },
                '&:last-child': {
                    marginLeft: '1em',
                }
            },
        },
        [theme.breakpoints.down('md')]: {
            '& > section': {
                width: '100%',
                marginRight: 0,
                marginLeft: 0,
            },
            flexWrap: 'wrap',
        }
    },
    regelwerk: {
        flex: '1 1 auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg':{
            fontSize: '1.5rem',
        },
    },
});

const ProgrammPage = ({data, classes}) => {
    let events = data.events.group.map((data, index) => {
        data.eventsByTime = [];
        let events = [];
        let time = null;
        data.edges.forEach(({node}, index) => {
            if(time!==null && time !== node.custom_fields.wpcf_tueroeffnung[0]) {
                data.eventsByTime.push({
                    time,
                    events
                });
                events = [];
            }

            let bildVoranzeige = node.custom_fields.wpcf_bild_voranzeige;
            if(bildVoranzeige){
                bildVoranzeige = bildVoranzeige[0]==='1';
            }

            let grosseBox = node.custom_fields.wpcf_grosse_box;
            if(grosseBox){
                grosseBox = grosseBox[0]==='1';
            }
            let image = null;
            if(bildVoranzeige){
                image = (node.veranstaltungsbild? node.veranstaltungsbild: node.featured_media).source_url;
            }
            let slug = '/'+node.slug;

            let taxonomy = node.custom_fields.taxonomies.reduce((acc, current) =>
                current.slug==='slam-2018'? acc: current
            );

            events.push({
                title: node.title,
                location: node.location.title,
                type: castProgramType(taxonomy.slug),
                big: grosseBox,
                image,
                slug
            });
            time = node.custom_fields.wpcf_tueroeffnung[0]
        });
        data.eventsByTime.push({
            time,
            events
        });
        data.date = dayjs(data.fieldValue*1000).locale('de');
        return (<ProgramDay day={index+1} date={data.date} events={data.eventsByTime}/>);
    });

    return (
        <Layout pageTitle={'Programm'} pageHeader={false}>
            <div className={classes.content + ' container'}>
                <h4>
                    Vom 6. – 10. November 2018 finden an der deutschsprachigen Poetry-Slam-Meisterschaft in Zürich 20 Wettbewerbsveranstaltungen in zwei Kategorien statt.
                </h4>
                <div className={classes.split}>
                    <div className={classes.contentText}>
                        <p>
                            Im Einzel-Wettbewerb kämpfen 144 PoetInnen in 12 Vorrunden um den Einzug in
                            eines der 4 Halbfinals und schliesslich um die Teilnahme am grossen Finale im Hallenstadion Zürich.
                            21 Poetry-Slam-Teams messen sich in der Kategorie «Team» um den Final-Einzug ins Zürcher Volkshaus.
                            Neben den Meisterschaftsanlässen bietet der «Slam 2018» ein öffentliches Rahmenprogramm.
                            Die ZuschauerInnen erwarten spannende Themen-Slams, Buchpräsentationen, ein Fussballspiel und zum Ausklang eine Party im "Great Gatsby"-Stil.
                        </p>
                        <Link style={{margin: '24px 0', width: '6.5em'}} to={'/programm/resultate'} className="button">Resultate</Link>
                    </div>
                    <a href={programPDF} className={classes.contentImage} target="_blank">
                        <GatsbyImage fluid={data.program_image.childImageSharp.fluid}/>
                        <FontAwesomeIcon icon={faFilePdf}/>
                        <span className="large">Programmheft herunterladen</span>
                        <span className="small">Herunterladen</span>
                    </a>
                </div>
            </div>
            <Program events={events}/>
            <div className={classes.infoContainer + ' container'}>
                <Box header={'Regeln'} smallHeader={true} controller={(
                    <div className={classes.regelwerk}>
                        <a href={regelwerk} target="_blank">
                            <FontAwesomeIcon icon={faFilePdf}/> Regelwerk
                        </a>
                    </div>
                )}>
                    <p style={{margin: '24px 0'}}>Eigentlich ist Poetry Slam ja unglaublich einfach: Die TeilnehmerInnen haben 6 Minuten Zeit, um das Publikum von ihren selbstverfassten Texten zu überzeugen. Dabei dürfen für die Performance weder Requisiten und Verkleidungen noch klangliche Begleitungen irgendwelcher Art zu Hilfe nehmen. Und das war’s schon.</p>
                </Box>
                <SmallBox header={'Road-To'}>
                    <div style={{margin: '0 24px'}}>
                        <p style={{margin: '24px 0'}}>
                            Auf der «Road-to-Slam2018»-Tour quer durch die Deutschschweiz finden bis zu den Deutschsprachigen Meisterschaften im November auserlesene Poetry Slam-Veranstaltungen im Zeichen des «Slam2018» statt. Stimmt mit ein in das grosse Jubiläumsjahr des Schweizer Poetry Slams!
                        </p>
                        <Link style={{margin: '24px 0', width: '5em'}} to={'/programm/road-to'} className="button">Weiter</Link>
                    </div>
                </SmallBox>
            </div>
        </Layout>
    );
};

export default injectSheet(styles)(ProgrammPage);

export const query = graphql`
    query ProgrammPageQuery {
        program_image: file(
            relativePath: {eq: "SLAM2018_Programm_Web.jpg"}
        ){
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }

        events: allWordpressWpEvents(
            filter: {content: {ne: ""}},
            sort: {fields: [custom_fields___wpcf_tueroeffnung]}
        ){
            group(field: custom_fields___wpcf_veranstaltungsdatum){
                field
                fieldValue
                totalCount
                edges{
                    node{
                        slug
                        title
                        featured_media{
                            source_url
                        }
                        location{
                            title
                        }
                        veranstaltungsbild{
                            source_url
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 400) {
                                        ...GatsbyImageSharpFluid_tracedSVG
                                    }
                                }
                            }
                        }
                        custom_fields{
                            location_id: _wpcf_belongs_location_id
                            wpcf_location
                            wpcf_untertitel
                            wpcf_tueroeffnung
                            
                            wpcf_bild_voranzeige
                            wpcf_grosse_box
                            
                            taxonomies{
                                slug
                            }
                        }
                    }
                }
            }
        }
    }
`;