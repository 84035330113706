import React from 'react';
import injectSheet from 'react-jss';

const styles = theme => {
    return {
        root: {
//            background: theme.palette.primary.dark,
        },
        program: {
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.up('xxl')]: {
                '&.container': {
                    margin: '1rem 0 0 .5rem',
                    width: 'calc(100% - 1rem)',
                }
            },
            [theme.breakpoints.down('xxl')]: {
                flexDirection: 'column',
            },
        },
    };
};

const Program = ({events, classes}) => {
    return (
        <div className={classes.root}>
            <article className={classes.program + " container"}>
                {events}
            </article>
        </div>
    );
};


export default injectSheet(styles)(Program);