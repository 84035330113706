import React from 'react';
import injectSheet from 'react-jss';
import ProgramEntry, {ProgramType} from "./ProgramEntry";

const styles = theme => {
    let background = theme.palette.primary.dark;
    let color = theme.palette.primary.light;
//    background = theme.palette.background;
//    color = theme.palette.secondary.light;
    color = theme.palette.backgroundSecondary;
    color = theme.palette.background;
    return {
        root: {
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('xxl')]: {
                flexDirection: 'row',
            },
            '&.special': {
                '& $title > div': {
                    background: theme.palette.backgroundSecondary,
                },
            },
            '&.team': {
                '& $title > div': {
                    background: theme.palette.secondary.dark,
                },
            },
        },
        title: {
            flex: '0 0 auto',
            alignSelf: 'flex-start',

            paddingTop: '1rem',
            paddingBottom: '1rem',

            width: '3rem',

            [theme.breakpoints.up('xxl')]: {
                width: '100%',
                position: 'sticky',
                top: 'calc(4.8rem + 5em)',
                zIndex: 125,
                background: theme.palette.background,
                opacity: .8,
            },
            [theme.breakpoints.down('xxl')]: {
                marginTop: '0rem',
                marginBottom: '0rem',
                marginRight: '1rem',
            },
            [theme.breakpoints.down('xxs')]: {
                marginLeft: '.5rem',
                marginRight: '.5rem',
                margin: 0,
                
                position: 'sticky',
                top: 100,
            },
            '& > div': {
                display: 'inline',
                background,
                color,
                borderRadius: 4,
                textTransform: 'uppercase',
                padding: '0rem .5rem',
                fontSize: '.75rem',
                fontWeight: 400,
            },
        },
        content: {
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('xxl')]: {
                flexDirection: 'row',
                flexWrap: 'wrap',
            },
        },
        contentImage: {
            width: 'calc(8rem - 2px)',
            height: '11rem',
//            border: '1px solid red',
            marginBottom: '1rem',
            marginRight: '1rem',
            flex: '1 1 auto',
            '&.singleGroup': {
                height: '5rem',
            },
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: 4,
            },
            [theme.breakpoints.down('xxl')]: {
                width: '12rem',
                maxWidth: '12rem',
            },
            [theme.breakpoints.down('xxs')]: {
                width: 'calc(8rem - 2px)',
                flex: '1 1 auto',
                marginLeft: '1rem',
            },
        },
    };
};

const ProgramGroup = ({title, events, singleGroup, classes}) => {
    if(title){
        title = (<div>{title}</div>);
    }

    let special = events[0].type === ProgramType.SPECIAL;
    let additionalClass = special? ' special': events[0].type === ProgramType.TEAM? ' team': '';
    let count = 4;
    let eventComponents = events.map((event, index) => {
        let image;
        if(event.image){
            count = count - (singleGroup? 1: 2);
            image = (
                <div className={classes.contentImage + (singleGroup? ' singleGroup': '')}>
                    <img src={event.image}/>
                </div>
            );
        }
        count--;
        if(event.big)
            count--;
        return (
            <>
            <ProgramEntry key={index} isSlam={!(!singleGroup && special)} {...event}>
                {event.location}
            </ProgramEntry>
            {image}
            </>
        );
    });
    for(let i=0; !special && i<count; i++){
        //Ghost Event (Placeholder)
        eventComponents.push((<ProgramEntry key={i+4} type={ProgramType.PLACEHOLDER}/>));
    }

    return (
        <div className={classes.root + additionalClass}>
            <header className={classes.title}>
                {title}
            </header>
            <div className={classes.content}>
                {eventComponents}
            </div>
        </div>
    );
};

export default injectSheet(styles)(ProgramGroup);