import React from 'react';
import injectSheet from 'react-jss';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons/faArrowRight';
import {faCircle} from "@fortawesome/free-solid-svg-icons/faCircle";
import Link from "../Link";
import { navigate } from "gatsby-link";

export const ProgramType = {
    PLACEHOLDER: 'PLACEHOLDER',
    SINGLE: 'SINGLE_SLAM',
    TEAM: 'TEAM_SLAM',
    SPECIAL: 'SPECIAL_EVENT',
};

const styles = theme => {
    let background = theme.palette.primary.dark;
    let color = theme.palette.primary.light;

//    background = theme.palette.primary.light;
//    color = theme.palette.backgroundSecondary;
    color = theme.palette.background;
    return {
        root: {
            width: '8rem',
            height: '5rem',
            marginBottom: '1rem',
            marginRight: '1rem',
            border: '2px solid '+ background,
            background,
            borderRadius: 4,
            '&.width': {
                [theme.breakpoints.up('xxl')]: {
//                flex: '1 1 auto',
                    width: '17rem',
                },
                [theme.breakpoints.down('xxl')]: {

                },
            },
            '&.placeholder': {
                visibility: 'hidden',
                [theme.breakpoints.down('xxl')]: {
                    display: 'none',
                }
            },
            '&.special': {
                borderColor: theme.palette.backgroundSecondary,
                background: theme.palette.backgroundSecondary,
                color: theme.palette.backgroundSecondary,
            },
            '&.team': {
                borderColor: theme.palette.secondary.dark,
                color: theme.palette.secondary.dark,
                background: theme.palette.secondary.dark,
            },
            '&.big': {
                height: '11rem',
                '& $title': {
                    fontSize: '1.2rem',
                    fontWeight: 500,
                    lineHeight: 1.5
                }
            },
            '&:hover': {
                cursor: 'pointer',
                border: '2px solid '+theme.palette.primary.dark,
                border: '2px solid '+theme.palette.primary.main,
                background: theme.palette.primary.dark,
                background: theme.palette.primary.main,
                borderRadius: 4,
                '& $header': {
                    color: theme.palette.primary.light,
                },
                '&.special': {
                    borderColor: theme.palette.primary.light,
                    background: theme.palette.primary.light,
                    borderColor: '#bfbfbf',
                    background: '#bfbfbf',
                    '& $header': {
                        color: theme.palette.primary.light,
                        color: '#1c1d21',
                        color: 'white',
                    },
                },
                '&.team': {
                    borderColor: theme.palette.secondary.main,
                    background: theme.palette.secondary.main,
                    color: theme.palette.secondary.dark,
                    '& $header': {
                        color: theme.palette.secondary.light,
                    },
                },
            },
            [theme.breakpoints.down('xxl')]: {
                width: '12rem',
            },
            [theme.breakpoints.down('xxs')]: {
                flex: '1 1 auto',
                marginLeft: '1rem',
            },
        },
        header: {
            height: '50%',
            display: 'flex',
            alignItems: 'center',
            textTransform: 'uppercase',
            color,
            padding: '0.25rem',
        },
        title: {
            flex: '1 1 auto',
            fontSize: '.9rem',
            fontWeight: 600,
        },
        navigation: {
//            position: 'absolute',
            paddingLeft: '.5rem',
//            left: '7.5rem',
//            top: 40,
//            color: theme.palette.primary.dark,
        },
        content: {
            height: '50%',
            padding: '0.5rem',
            fontSize: '.9rem',
            fontWeight: 300,
            lineHeight: 1,
            background: theme.palette.background,
            borderRadius: 4,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    };
};

const getClassName = (type) => {
    switch(type){
        case ProgramType.PLACEHOLDER:
            return ' placeholder';
        case ProgramType.SPECIAL:
            return ' special';
        case ProgramType.TEAM:
            return ' team';
        case ProgramType.SINGLE:
        default:
            return '';
    }
};

const ProgramEntry = ({title, type, big, slug, children, isSlam=true, classes}) => {
    let additionalClass = getClassName(type);
    additionalClass += big? ' big': '';
    additionalClass += isSlam? '': ' width';
    return (
        <section className={classes.root + additionalClass} onClick={() => navigate(slug)}>
            <header className={classes.header}>
                <span className={classes.title}>{title}</span>
            </header>
            <div className={classes.content}>
                <div>
                    {children}
                </div>
                <Link to={slug} className={classes.navigation + " fa-layers fa-fw"}>
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon icon={faArrowRight} transform={{size: 10}} className={'invert'}/>
                </Link>
            </div>
        </section>
    );
};

export default injectSheet(styles)(ProgramEntry);